import { Fragment, useState } from "react";
import { isJSDocCallbackTag } from "typescript";
import { formatMoney } from "../Services/HelperMethods";
import "./Top.scss";

export default function GeneralTop(props: { availableMoney; players }) {
  const [showInfo, setShowInfo] = useState(false);
  return (
    <div className="top-component">
      <div
        className="info-icon"
        style={{ cursor: "pointer" }}
        onClick={() => {
          setShowInfo(!showInfo);
        }}
      ></div>
         {showInfo ? (
        <div className="info-message">
          <div className="game-info">
            <div className="title">HOW TO PLAY &amp; WIN</div>
            <div className="close-btn" onClick={() => { setShowInfo(false) }}>
              <span className="material-icons">
                cancel
              </span>
            </div>
            <div style={{ fontWeight: "bold" }}>
              By entering the quiz, you accept to be bound by the following rules:
            </div>
            <ul style={{ marginTop: "0.5rem" }}>
              <li style={{ fontSize: "0.9rem" }}>
                Answer all the questions correctly to win a share of the prize fund.
              </li>
              <li style={{ fontSize: "0.9rem" }}>
                If you answer incorrectly, your participation in the quiz will end.
              </li>
              <li style={{ fontSize: "0.9rem" }}>
                Once your participation in a quiz has ended, spectator mode will allow you to view the remainder of the quiz.
              </li>
              <li style={{ fontSize: "0.9rem" }}>
                If no-one answers all 20 questions correctly, the prize fund will roll over to the next quiz.
              </li>
              <li style={{ fontSize: "0.9rem" }}>
                In the event that more than one player answers all 20 questions correctly,
                the prize fund shall be split equally amongst the winners.
              </li>
              <li style={{ fontSize: "0.9rem" }}>
                This quiz is a real-money-only game.
              </li>
              <li style={{ fontSize: "0.9rem" }}>
                If you experience a disconnect, the quiz service will attempt to rejoin you at the same question.
                Any questions missed as a result of the disconnect will be treated as incorrect answers and Cat Monocle Ltd,
                its partners and affiliates shall not be liable for the consequences of any disconnect, regardless of the cause.
              </li>
              <li style={{ fontSize: "0.9rem" }}>
                In the event of any dispute regarding the quiz,
                including any dispute regarding the questions and answers,
                the decision of Cat Monocle Ltd shall be final and no correspondence shall be entered into.
              </li>
              <li style={{ fontSize: "0.9rem" }}>
                Anyone believed to be cheating,
                collaborating or using the internet or automated methods to obtain answers shall be disqualified from the quiz.
              </li>
              <li style={{ fontSize: "0.9rem" }}>
                Cat Monocle Ltd can amend these rules without notice.
              </li>
              <li style={{ fontSize: "0.9rem" }}>
                The quiz and these rules shall be governed by the law of England and Wales.
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <Fragment />
      )}
      <div className="content">
        <div className="player-count">
          <div className="text">{props.players}</div>
          <div className="icon"></div>
        </div>
        <div className="separator"></div>
        <div className="prize-fund">{formatMoney(props.availableMoney)}</div>
      </div>
    </div>
  );
}
