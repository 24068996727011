import { useParams } from "react-router-dom";

export function SetAuth() {
    const { token } = useParams() as any;
    //console.log('token', token);
    if (token) {
        localStorage.setItem("auth", token);
        window.location.replace("/");
    }

    return <></>;
}

