import { createContext, useState } from "react";

export const StartTimerStore = createContext([] as any[]);
export const PlayerBalanceStore = createContext([] as any[]);
export const GameIdStore = createContext([] as any[]);
export const TotalJackpot = createContext([] as any[]);
export const TotalPlayers = createContext([] as any[]);
export const GameStatus = createContext([] as any[]);
export const GameAnswerStore = createContext([] as any[]);
export const LastQuestionStore = createContext([] as any[]);
export const QuestionTimer = createContext([] as any[]);
export const LastCorrectAnswer = createContext([] as any[]);
export const CurrentQuestionNumber = createContext([] as any[]);
export const PlayerGameStatus = createContext([] as any[]);
export const IsAuth = createContext([] as any[]);
export const ChatList = createContext([] as any[]);
export const RefreshInitData = createContext([] as any[]);
export const FirstAnswerPercentage = createContext([] as any[]);
export const SecondAnswerPercentage = createContext([] as any[]);


export default function Store({ children }) {
  const [startTimer, setStartTimer] = useState(null);
  const [playerBalance, setPlayerBalance] = useState(0);
  const [gameId, setGameId] = useState("");
  const [totalPlayers, setTotalPlayers] = useState(0);
  const [totalJackpot, setTotalJackpot] = useState(0);
  const [gameStatus, setGameStatus] = useState("");
  const [isAuth, setIsAuth] = useState(false);
  const [chatList, setChatList] = useState([] as any[]);

  // in game
  const [firstAnswer, setFirstAnswer] = useState("");
  const [secondAnswer, setSecondAnswer] = useState("");
  const [lastQuestion, setLastQuestion] = useState("");
  const [lastCorrectAnswer, setLastCorrectAnswer] = useState("");
  const [questionTimer, setQuestionTimer] = useState(0);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
  const [playerGameStatus, setPlayerGameStatus] = useState("");
  const [refreshInitDate, setRefreshInitData] = useState("");
  const [firstAnswerPercentage, setFirstAnswerPercentage] = useState("");
  const [secondAnswerPercentage, setSecondAnswerPercentage] = useState("");


  return (
    <IsAuth.Provider value={[isAuth, setIsAuth]}>
      <PlayerBalanceStore.Provider value={[playerBalance, setPlayerBalance]}>
        <StartTimerStore.Provider value={[startTimer, setStartTimer]}>
          <GameIdStore.Provider value={[gameId, setGameId]}>
            <TotalJackpot.Provider value={[totalJackpot, setTotalJackpot]}>
              <TotalPlayers.Provider value={[totalPlayers, setTotalPlayers]}>
                <GameStatus.Provider value={[gameStatus, setGameStatus]}>
                  <ChatList.Provider value={[chatList, setChatList]}>
                  <RefreshInitData.Provider value={[refreshInitDate, setRefreshInitData]}>

                    {/* in game */}
                    <GameAnswerStore.Provider value={[firstAnswer, setFirstAnswer, secondAnswer, setSecondAnswer]}>
                      <LastQuestionStore.Provider value={[lastQuestion, setLastQuestion]}>
                        <LastCorrectAnswer.Provider value={[lastCorrectAnswer, setLastCorrectAnswer]}>
                          <QuestionTimer.Provider value={[questionTimer, setQuestionTimer]}>
                            <CurrentQuestionNumber.Provider value={[currentQuestionNumber, setCurrentQuestionNumber]}>
                              <PlayerGameStatus.Provider value={[playerGameStatus, setPlayerGameStatus]}>
                              <FirstAnswerPercentage.Provider value={[firstAnswerPercentage, setFirstAnswerPercentage]}>
                              <SecondAnswerPercentage.Provider value={[secondAnswerPercentage, setSecondAnswerPercentage]}>
                                {children}
                              </SecondAnswerPercentage.Provider>
                              </FirstAnswerPercentage.Provider>
                              </PlayerGameStatus.Provider>
                            </CurrentQuestionNumber.Provider>
                          </QuestionTimer.Provider>
                        </LastCorrectAnswer.Provider>
                      </LastQuestionStore.Provider>
                    </GameAnswerStore.Provider>

                  </RefreshInitData.Provider>
                  </ChatList.Provider>
                </GameStatus.Provider>
              </TotalPlayers.Provider>
            </TotalJackpot.Provider>
          </GameIdStore.Provider>
        </StartTimerStore.Provider>
      </PlayerBalanceStore.Provider>
    </IsAuth.Provider>
  );
}

