import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GeneralTop from "../../components/GeneralTop";
import ApiService from "../../Services/ApiServices";
import {
  formatMoney,
  getServerName,
  handleErrors,
  validateBot,
} from "../../Services/HelperMethods";
import "./Entry.scss";
import moment from "moment";
import {
  GameIdStore,
  PlayerBalanceStore,
  PlayerGameStatus,
  RefreshInitData,
  StartTimerStore,
  TotalJackpot,
  TotalPlayers,
} from "../../store/Store";
import Loader from "react-loader-spinner";
import Helmet from "react-helmet"

export default function Entry() {
  const history = useHistory();
  const apiService = new ApiService();
  const [timer, setTimer] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPlayers, setTotalPlayers] = useContext(TotalPlayers);
  const [totalJackpot, setTotalJackpot] = useContext(TotalJackpot);
  const [startTime, setStartTime] = useContext(StartTimerStore);
  const [appSyncGameId, setAppSyncGameId] = useContext(GameIdStore);
  const [availableMoney] = useContext(PlayerBalanceStore);
  const [playerGameStatus, SetPlayerGameStatus] = useContext(PlayerGameStatus);
  const [refreshInitDate, setRefreshInitData] = useContext(RefreshInitData);
  let timeout;

  // useEffect(() => {
  //   console.log('entry screen', playerGameStatus);
  //   if (playerGameStatus == "Init") {
  //     if (appSyncGameId) {
  //       history.push(
  //         "/count/" + appSyncGameId
  //       );
  //     }
  //   }
  // }, [playerGameStatus]);

  const toggleCaptchaBadge = (show: boolean) => {
    const badge = document.getElementsByClassName("grecaptcha-badge")[0];
    if (badge && badge instanceof HTMLElement) {
      badge.style.visibility = show ? "visible" : "hidden";
    }
  };

  useEffect(() => {
    toggleCaptchaBadge(true);
    return () => toggleCaptchaBadge(false);
  }, []);

  useEffect(() => {
    setRefreshInitData(Math.random());
    getInitData();
  }, []);

  //timer
  useEffect(() => {
    if (startTime) {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        var diff = moment(startTime).diff(moment(), "seconds");
        if (diff > 0) {
          setTimer(diff);
        } else {
          setTimer(0);
          getInitData();
        }
      }, 1000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [startTime, timer]);

  async function getInitData() {
    try {
      let data = (await apiService.getInitData({
        serverName: getServerName(),
      })) as any;
      data = data.data;
      console.log("init data", data);
      setStartTime(data.entryGameStartTime);
      setAppSyncGameId(data.lastGameAppSyncId);

      if (!appSyncGameId) {
        setTotalPlayers(data.totalPlayers);
        setTotalJackpot(data.totalJackpot);
      }

      //redirect if already in the game
      if (data.playerGameStatus == "Init") {
        history.push("/count/" + data.playerGameAppSyncId);
      } else if (
        data.playerGameStatus == "Current" ||
        data.playerGameStatus == "Started"
      ) {
        history.push("/game/" + data.playerGameAppSyncId);
      }
      // SetPlayerGameStatus(data.playerGameStatus);
    } catch (error) {
      handleErrors(error);
    }
  }

  async function registerForNextGame() {
    setIsLoading(true);
    validateBot(async (token) => {
      try {
        let result = (await apiService.registerPlayerForNextGame({
          gCaptcha: token,
          Server: getServerName(),
        })) as any;
        result = result.data;
        //console.log('pushed', appSyncGameId);

        history.push("/count/" + appSyncGameId);
      } catch (error: any) {
        if (error.response?.data?.type == "same_user") {
          history.push("/count/" + appSyncGameId);
          // toast.info("Already in the game, No money has been deducted")
        } else {
          handleErrors(error);
        }
        setIsLoading(false);
      }
    }, "PlayGame");

    // entrySocketService.registerForNextGame(() => {
    //   setState({ isEntered: true });
    //   // warnRefresh();
    // });
  }

  return (
    <div className="layers">
      <div className="entry-page">
        <div className="music-image" />
        <GeneralTop players={totalPlayers} availableMoney={availableMoney} />
        <div className="content-wrap">
          <div className="fiftyimg"></div>
          <div className="game-logo"></div>
          <div className="description">
            Answers fast and correct to stay in the game. <br />
            last remaining players win the prize fund!
          </div>
          <div className="play-button-wrap">
            <div className="cup-image"></div>
            <div className="price-fund">
              <div className="text">Prize Fund&nbsp;</div>
              <div className="number">{formatMoney(totalJackpot)}</div>
            </div>
            <div
              className="play-button"
              onClick={() => {
                if (!isLoading) {
                  registerForNextGame();
                }
              }}
            >
              {isLoading ? (
                <div style={{ paddingRight: 20 }}>
                  <Loader
                    type="TailSpin"
                    color="white"
                    height={20}
                    width={20}
                  />
                </div>
              ) : (
                <div className="text">PLAY {formatMoney(1)}</div>
              )}
            </div>
            {timer ? (
              <div className="starts-in">
                <div className="icon"></div>
                <div className="content">
                  <div className="text">Starts In&nbsp;</div>
                  <span className="number">{timer}</span>
                </div>
              </div>
            ) : (
              <div>
                {/* <div className="starts-in">
                  <div className="icon rotate"></div>
                </div> */}
                <div
                  style={{ textAlign: "center", color: "white", opacity: 0.8 }}
                >
                  <div className="content">
                    <div
                      style={{ fontSize: 12, lineHeight: "13px", marginTop: 5 }}
                      className="text"
                    >
                      {" "}
                      Refreshing Session.
                      <br />
                      Please wait a few seconds.
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="footer">
          <div>
            <Helmet>
              <script src="https://www.google.com/recaptcha/api.js?render=6LdiRy8kAAAAANsgSybFjf0wIBFGB0DDClEuwyY6"></script>
            </Helmet>
          </div>
          {/* <ExitQuizButton>
            <div className="exit">
              <div className="icon"></div>
            </div>
          </ExitQuizButton> */}
          {/* <Reactions gameId={appSyncGameId} /> */}
        </div>
      </div>
    </div>
  );
}
