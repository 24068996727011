import React, { Fragment, useContext, useEffect, useState } from "react";
import { formatMoney } from "../../Services/HelperMethods";
import "./GameEnd.scss";
import { useParams, useHistory } from "react-router-dom";
import PrizeFundBanner from "../../components/PrizeFundBanner";
import ApiService from "../../Services/ApiServices";
import Reactions from "../../components/Reactions/Reactions";
import { GameIdStore } from "../../store/Store";
import ExitQuizButton from "../../components/ExitQuizButton";
import Slider from "react-slick";

export default function GameEnd() {
  const history = useHistory();
  const [totalJackpot, setTotalJackpot] = useState(0);
  const [winners, setWinners] = useState([]);
  const apiService = new ApiService();
  const { gameId } = useParams() as any;
  const [count, setCount] = useState(0);
  const [appSyncGameId, setAppSyncGameId] = useContext(GameIdStore);
  const [isLoading, setIsLoading] = useState(true);
  const [wonPriceEach, setWonPriceEach] = useState("");
  let settings = {
    dots: false,
    infinite: getSlideNum(1),
    speed: 500,
    slidesToShow: getSlideNum(3),
    slidesToScroll: 1,
    autoplay: 1,
    autoPlaySpeed: 1000,
    arrows:winners.length>1? true:false,
    // centerMode: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: getSlideNum(2),
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: getSlideNum(1),
        }
      }
    ]
  }

  function getSlideNum(def) {
    return winners?.length > 3 ? def : winners?.length - 1
  }
  useEffect(() => {
    getGameStatus();
  }, [])
  async function getGameStatus() {
    setIsLoading(true);
    apiService.getGameStatus(gameId).then((data: any) => {
      data = data.data;
      //console.log('game end', data);
      setTotalJackpot(data.jackpot);
      setWonPriceEach(data?.wonInfo?.wonPriceEach);
      let wonPlayers = data?.wonInfo?.wonPlayers;
      if (wonPlayers) {
        setWinners(wonPlayers);
      }
      setCount(data.totalActive);
      setIsLoading(false);

    });
  }

  return (
    <div className="layers">

      <div className="game-end-page">
        {isLoading ? <Fragment /> : count != 0 ? (
          <div className={"winning-banner"}>
            <div className="img">
              <div className="winning-number">{count}</div>
              <div className="winning-label">
                {count == 1 ? "WINNER" : count > 1 ? "WINNERS" : ""}
              </div>
              <div className="content">
                <div className="text">Winnings</div>
                <div className="number">{formatMoney(totalJackpot)}</div>
              </div>
            </div>
          </div>
        ) : (
          <PrizeFundBanner jackpot={totalJackpot} />
        )}

        {isLoading ? <div className="winners-content" /> : winnerContentUI()}

        <div className="play-again">
          <div className="play-button-wrap">
            <div
              className="play-button"
              onClick={() => {
                history.push("/");
              }}
            >
              <div className="text">PLAY AGAIN</div>
             
            </div>
           
          </div>
        </div>
        <div className="footer">
          {/* <ExitQuizButton>
            <div className="exit">
              <div className="icon"></div>
            </div>
          </ExitQuizButton> */}
         <Reactions gameId={appSyncGameId} />
          
        </div>
      </div>
    </div>
  );

  function winnerContentUI() {

    if (count > 0) {
      return (
        <div className="winners-content">
          <div>
            <div>
              <Slider {...settings}>
                {winners?.map((w, i) => {
                  return (
                    <div key={i} className="winner" style={{ margin: 10 }}>
                      <div className="img"></div>
                      <div className="name">
                        <div className="text">{w}</div>
                      </div>
                      <div className="price">
                        <div className="text" style={{ marginTop: "0.4rem" }}>{formatMoney(wonPriceEach)}</div>
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </div>
          </div>
          {/* <div className="winner two">
            <div className="img"></div>
            <div className="name">
              <div className="text">TOM</div>
            </div>
          </div>
          <div className="winner one">
            <div className="img"></div>
            <div className="name">
              <div className="text">TOM</div>
            </div>
          </div>
          <div className="winner three">
            <div className="img"></div>
            <div className="name">
              <div className="text">TOM</div>
            </div>
          </div> */}
        </div>
      );
    }

    return (
      <div className="winners-content no-win">
        <div className="no-winner">
          <div className="circle-wrap rotate">
            <div className="inner-shadows"></div>
            <div className="img"></div>
          </div>
          <div className="start-img-background"></div>
          <div className="text">
            <div className="h2">ROLL OVER</div>
            <p>
              Play again for the <br /> chance to win a <br /> bigger prize
            </p>
          </div>
        </div>
      </div>
    );
  }
}
