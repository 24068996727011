export default class Config {
  isOnDebugMode =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development";
 
  getBaseURL() {
    if (this.isOnDebugMode==true) {
      console.log("link",this.isOnDebugMode);
      return "https://localhost:44303";
      
    }
      return "https://fiftydemo.catmonocle.com";
  }

  getGameType() {
    let url_string = (window as any).location.href; //window.location.href
    let url = new URL(url_string);

    return url.searchParams.get("GameType");
  }
}
