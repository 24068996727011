import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import awsconfig from "./aws-exports";
import Amplify from "aws-amplify";
import Store from "./store/Store";

Amplify.configure(awsconfig);

ReactDOM.render(
  <React.StrictMode>
    <Store>
      <App />
    </Store>
  </React.StrictMode>,
  document.getElementById("root")
);
