import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { subscribeGame } from "../../pages/Subscriptions/AppSyncSub";
import ApiService from "../../Services/ApiServices";
import { getServerName, handleErrors } from "../../Services/HelperMethods";
import { ChatList } from "../../store/Store";
import './Reactions.scss';

export default function (props: { gameId: string }) {
    const apiService = new ApiService();
    const [chatList, setChatList] = useContext(ChatList);
    let [initTime, setInitDate] = useState(moment())
    const [localChat, setLocalChat] = useState([] as any[]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let filterChat = chatList.filter((t) => {
            let tx = moment.utc(t.DateTime).local();
            return initTime < tx;
        })
        if (filterChat && filterChat?.length) {

            let lastDate = filterChat[filterChat.length - 1].DateTime;
            setInitDate(moment.utc(lastDate).local());
            setLocalChat(filterChat);
           // console.log('chat', chatList, filterChat);
        }

    }, [chatList])

    useEffect(() => {
        if (localChat) {
            localChat.forEach((c) => {
                let $ = (window as any).jQuery;
                let rand = Math.floor(Math.random() * 3);
                let unid = Date.now() + Math.floor(Math.random());
                $('.reactions .' + c.Text).append(`<div class="clicked cl${rand.toString()} x${unid}"></div>`)
                setTimeout(() => {
                    $('.x' + unid).remove();
                }, 2500, unid)
            })
        }
    }, [localChat])
    return (
        <div className={isLoading ? "working reactions" : "reactions"}>
            <div className="icn happy"
                onClick={() => {
                    handleReaction('happy');
                }}>
            </div>
            <div className="icn sad"
                onClick={() => {
                    handleReaction('sad');
                }}>
            </div>
            <div className="icn clap" onClick={() => {
                handleReaction('clap');
            }}>
            </div>
        </div>
    )

    async function handleReaction(reaction: string) {
        if (isLoading) {
            return;
        }
        
        try {
            setIsLoading(true);
            await apiService.addChat({ emojiType: reaction, gameId: props.gameId });
        } catch (error) {
            handleErrors(error);
        }
        setIsLoading(false);
    }
}

