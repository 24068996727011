import { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { formatMoney, getPlayerBalanceStr } from "../../Services/HelperMethods";
import "./Countdown.scss";
import GeneralTop from "../../components/GeneralTop";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import PrizeFundBanner from "../../components/PrizeFundBanner";
import moment from "moment";
import { GameIdStore, GameStatus, PlayerBalanceStore, StartTimerStore, TotalJackpot, TotalPlayers } from "../../store/Store";
import ApiService from "../../Services/ApiServices";
import Reactions from "../../components/Reactions/Reactions";
import { toast } from "react-toastify";

export default function Countdown() {
  const history = useHistory();
  const [timer, setTimer] = useState(0);
  const [totalPlayers, setTotalPlayers] = useContext(TotalPlayers);
  const [totalJackpot, setTotalJackpot] = useContext(TotalJackpot);
  const [startTime, setStartTime] = useContext(StartTimerStore);
  const { gameId } = useParams() as any;
  const [status] = useContext(GameStatus);
  const [firstTimeStartTime, setFirstTimeStartTime] = useState("" as any);
  const apiService = new ApiService();
  const [availableMoney, setAvailableMoney] = useContext(PlayerBalanceStore);
  let timeout;
  const [appSyncGameId, setAppSyncGameId] = useContext(GameIdStore);

  useEffect(() => {
    getPlayerBalance();
  }, []);

  useEffect(() => {

  }, [status])

  useEffect(() => {
   // console.log('countdown', gameId);
    var diff = moment(startTime).diff(moment(), "seconds");
    setFirstTimeStartTime(diff);
    getStatus();
 
  }, []);

  async function getStatus(){
   // console.log('countdown get stats');
    
    apiService.getGameStatus(gameId).then((data: any) => {
      data = data.data;
      setTotalJackpot(data.jackpot)
      setTotalPlayers(data.totalPlayers);

      if (data.status == "CrashedAfterStartedFromSeed" || data.status == "CrashedAfterStarted") {
        toast.error(data.errorMessage)
        history.push("/")
      }
    })
  }

  useEffect(() => {
    if (startTime) {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        var diff = moment(startTime).diff(moment(), "seconds");
        if (diff > 0) {
          setTimer(diff);
        } else {
          setTimer(0);
          getStatus();
        }
      }, 1000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [startTime, timer]);


  async function getPlayerBalance() {
    let playerBalance = await getPlayerBalanceStr();
    setAvailableMoney(playerBalance);
  }

  return (
    <div className="layers">
      <div className="countdown">
        <div className="top">
          <GeneralTop availableMoney={availableMoney} players={totalPlayers} />
          <PrizeFundBanner jackpot={totalJackpot} />
        </div>
        <div className="img-mobile"></div>

        <div className="clock">
          <div className="text"> {timer != 0 ? timer : "GET READY"}</div>
          <div
            className={
              timer == 0 ? "show start-img-background" : "start-img-background"
            }
          ></div>
          {/* <div ref={counterBarRef} id="counterBar"></div> */}
          <CircularProgressbar
            counterClockwise={true}
            value={timer == 0 ? firstTimeStartTime : timer}
            maxValue={firstTimeStartTime}
            strokeWidth={14}

            background={false}
            styles={buildStyles({
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: "butt",
              // How long animation takes to go from one percentage to another, in seconds
              pathTransitionDuration: 0.5,
              // Colors
              pathColor: "#F5DB1E",
              trailColor: "transparent",
            })}
          />
        </div>

        <div className="mobile-pricefund">
          <div className="money">{formatMoney(totalJackpot)}</div>
          <div className="text">Prize Fund</div>
        </div>
      </div>

      <div className="footer">
        <Reactions gameId={appSyncGameId} />
      </div>
    </div>
  );
}
