/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateFiftySync = /* GraphQL */ `
  subscription OnCreateFiftySync($id: ID) {
    onCreateFiftySync(id: $id) {
      id
      createdDate
      status
      entryCountTime
      lastQuestion
      lastAnswers
      lastCorrectAnswer
      questionCountTimer
      jackpot
      totalPlayers
      serverName
      gamePlayersStatus
      currentQuestionNumber
      answerStatus
      chat
    }
  }
`;
export const onUpdateFiftySync = /* GraphQL */ `
  subscription OnUpdateFiftySync($id: ID) {
    onUpdateFiftySync(id: $id) {
      id
      createdDate
      status
      entryCountTime
      lastQuestion
      lastAnswers
      lastCorrectAnswer
      questionCountTimer
      jackpot
      totalPlayers
      serverName
      gamePlayersStatus
      currentQuestionNumber
      answerStatus
      chat
    }
  }
`;
export const onDeleteFiftySync = /* GraphQL */ `
  subscription OnDeleteFiftySync($id: ID) {
    onDeleteFiftySync(id: $id) {
      id
      createdDate
      status
      entryCountTime
      lastQuestion
      lastAnswers
      lastCorrectAnswer
      questionCountTimer
      jackpot
      totalPlayers
      serverName
      gamePlayersStatus
      currentQuestionNumber
      answerStatus
      chat
    }
  }
`;
