import { toast } from "react-toastify";
import ApiService from "./ApiServices";

export function formatMoney(value: number) {
  try {
    value = parseFloat(value.toString());
    var val = value?.toLocaleString("en-US", {
      style: "currency",
      currency: "GBP",
    });

    if (val?.includes("NaN")) {
      val = "...";
    }
    return val;

  } catch (error) {
    return "";
  }
}

export async function getPlayerBalanceStr() {
  try {
    let apiService = new ApiService();
    let data = await apiService.getBalance() as any;
    data = data.data;
    // console.log('playerb', data.availableMoney);
    if (data.availableMoney > 0) {
      return data.availableMoney / 100
    }

    return 0;

  } catch (error) {
   // console.log(error);
    // handleErrors(error);
  }
}

export function getAuthUser(): any {
  let str = localStorage.getItem("auth");
  return str;
}

export function setAuthUser(data): any {
  localStorage.setItem("auth", data.data.jwt);
}

let currentIp = "aa";
export async function getCurrentIP() {
console.log("current ip", currentIp);

  if (!currentIp) {
    let jQuery = (window as any).$;
    return new Promise((resolve, reject) => {
      jQuery.getJSON(
        "https://888.catmonocle.cloud/auth/ip",
        (data) => {
          let ip = data.ip;
          currentIp = ip;
          resolve({
            clientIp: ip,
          });
        },
        () => {
          reject("failed to get ip address");
        }
      );
    });
  }
  return new Promise((resolve, reject) => {
    return resolve({
      clientIp: currentIp,
    });
  });
}

export function urlParams() {
  let url_string = window.location.href; //window.location.href
  let url = new URL(url_string);

  return {
    token: url.searchParams.get("Token"),
    gameType: url.searchParams.get("GameType"),
    networkID: url.searchParams.get("NetworkID"),
    playerID: url.searchParams.get("PlayerID"),
    serverName: getServerName().toString(),
    name: url.searchParams.get("DisplayName"),
  };
}

export function handleErrors(error) {
  console.error(error);

  if (error.response?.data?.message) {
    toast.error(error.response?.data?.message);
  } else if (error?.message) {
    toast.error(error?.message);
  } else {
    toast.error("Request failed!");
  }
}

export function fancyTimeFormat(duration) {
  // Hours, minutes and seconds
  var hrs = ~~(duration / 3600);
  var mins = ~~((duration % 3600) / 60);
  var secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
}

export function showError(err) {
  toast.error(err);
}

export function getServerName() {
  let url_string = window.location.href; //window.location.href
  let url = new URL(url_string);
  var gType = url.searchParams.get("GameType");

  if (gType == '23008') {
    return 'music';
  } else if (gType == '23010') {
    return 'noughties';
  } else if (gType == '23007') {
    return 'eighties';
  } else if (gType == '23009') {
    return 'nineties';
  }
  else if (gType == '23006') {
    return 'hollywood';
  }
  return "";
}

export function validateBot(callBack: CallableFunction, actionName: string) {
  callBack();
  // let grecaptcha = (window as any).grecaptcha;

  // grecaptcha.ready(function () {
  //   grecaptcha
  //     .execute("6LdiRy8kAAAAANsgSybFjf0wIBFGB0DDClEuwyY6", {
  //       action: actionName,
  //     })
  //     .then(function (token) {
  //       callBack(token);
  //     })
  //     .catch((err) => {
  //       console.log("gr error", err);
  //       alert(
  //         "We are unable to load Google reCAPTCHA!. Please reload the game"
  //       );
  //     });
  // });
}
