import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import Card from "../../components/Card";
import { fancyTimeFormat, formatMoney, getServerName, handleErrors, showError } from "../../Services/HelperMethods";
import "./Game.scss";
import * as ProgressBar from "progressbar.js";
import { useHistory, useParams, Link } from "react-router-dom";
import { subscribeGame } from "../Subscriptions/AppSyncSub";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import ApiService from "../../Services/ApiServices";
import { CurrentQuestionNumber, FirstAnswerPercentage, GameAnswerStore, GameStatus, LastCorrectAnswer, LastQuestionStore, QuestionTimer, SecondAnswerPercentage, StartTimerStore, TotalJackpot, TotalPlayers } from "../../store/Store";
import moment from "moment";
import Reactions from "../../components/Reactions/Reactions";
import { toast } from "react-toastify";

export default function Game() {
  const [selected, setSelected] = useState("");
  const history = useHistory();
  const { gameId } = useParams() as any;
  const [sub, setSub] = useState(null as any);
  const [questionText, setQuestionText,correctanswer] = useContext(LastQuestionStore);
  const [totalJackpot] = useContext(TotalJackpot);
  const [totalActivePlayers, setTotalActivePlayers] = useState(0);
  const [totalPlayers, setTotalPlayers] = useContext(TotalPlayers);
  const [firstAnswer, setFirstAnswer, secondAnswer, setSecondAnswer] = useContext(GameAnswerStore);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useContext(CurrentQuestionNumber);
  const [questionTimer] = useContext(QuestionTimer as any);
  const [lastCorrectAnswer, setLastCorrectAnswer] = useContext(LastCorrectAnswer);
  const [isSpectating, setIsSpectating] = useState(false);
  const [status] = useContext(GameStatus);
  const [firstAnswerPercentage, SetFirstAnswerPercentage] = useContext(FirstAnswerPercentage);
  const [secondAnswerPercentage, SetSecondAnswerPercentage] = useContext(SecondAnswerPercentage);

  const [showEliminatedModal, setShowEliminatedModal] = useState(false);
  const [showSpectatingModal, setShowSpectatingModal] = useState(false);
  const [startTime, setStartTime] = useState("");
  const apiService = new ApiService();
  const [timer, setTimer] = useState(0);
  let timeout;
  const [appSyncGameId, setAppSyncGameId] = useState("");
  let gameResetTime = 0;
  const firstButtonRef = useRef(null);
  const secondButtonRef = useRef(null);

  async function getInitData() {
    try {

      let data = (await apiService.getInitData({
        serverName: getServerName(),
      })) as any;
      data = data.data;
      //console.log("init data", data);
      setStartTime(data.entryGameStartTime);
      gameResetTime = data.gameStartMaxTime;

      if (data.playerGameStatus == "Finished") {
        getGameStatus();
      }

    } catch (error) {
      handleErrors(error);
    }
  }

  useEffect(() => {
    if (appSyncGameId) {
      if (sub) {
        sub.unsubscribe();
      }
      getInitData();
      // subscribeGame(appSyncGameId, (d) => {
      //   setSub(d.unsub);
      //   setStartTime(d.data.entryCountTime);
      // });
    }
    return () => {
      sub?.unsubscribe();
    };
  }, [appSyncGameId]);


  useEffect(() => {
    getGameStatus();
  });

  useEffect(() => {
    if (status == "Finishing") {
      getInitData();
    } else if (status == "Finished") {
      getInitData();
    } else if (status == "CrashedAfterStarted" || status == "CrashedAfterStartedFromSeed") {
      getGameStatus();
    }
  }, [status])


  useEffect(() => {
    if (isSpectating) {
      getInitData();
    }
  }, [isSpectating])

  useEffect(() => {
    if (questionText) {
      setSelected("");
    }
  }, [questionText]);

  useEffect(() => {

    if (lastCorrectAnswer) {

      setTimeout(() => {
        let $ = (window as any).$;
        const btns = [firstButtonRef?.current, secondButtonRef?.current];
        btns.forEach(btn => {
          if ($(btn).hasClass('wrong')) {
            $(btn).addClass("wrong-blank");
            $(btn).removeClass("wrong");
          }
          if ($(btn).hasClass('correct')) {
            $(btn).addClass("correct-blank");
            $(btn).removeClass("correct");
          }
        });
      }, 1500);
    }

  }, [lastCorrectAnswer])

  useEffect(() => {
    console.log("lcr", lastCorrectAnswer);
  }, [lastCorrectAnswer]);

  //timer
  useEffect(() => {
    if (startTime) {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        var diff = moment(startTime).diff(moment(), "seconds");
        if (diff > 0) {
          setTimer(diff);
        } else {
          setTimer(0);
          getInitData();
        }
      }, 1000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [startTime, timer]);

  async function selectAnswer(answer) {
    setSelected(answer);
    try {
      await apiService.playerSendAnswer({
        answer,
        gameId,
      });

    } catch (error) {
      handleErrors(error);
      setSelected("");
    }
  }

  async function getGameStatus() {
    //console.log('game get status');
    apiService.getGameStatus(gameId).then((data: any) => {
      data = data.data;
      if (data.status == "CrashedAfterStartedFromSeed" || data.status == "CrashedAfterStarted") {
        setSelected("");
        setLastCorrectAnswer("");
        setFirstAnswer("");
        setSecondAnswer("");
        setQuestionText("");
        setCurrentQuestionNumber(0);

        toast.error(data.errorMessage)
        history.push("/")
      }

      setTotalActivePlayers(data.totalActive);
      setTotalPlayers(data.totalPlayers);


      if (data.playerStatus.status == 'Eliminated' && !isSpectating && totalActivePlayers > 1) {
        setIsSpectating(true);
        setShowEliminatedModal(true);

        setTimeout(() => {
          setShowEliminatedModal(false);
          setShowSpectatingModal(true);
          setTimeout(() => {
            setShowSpectatingModal(false);
          }, 3000)
        }, 3000)
      }

      if (data.status == 'Finished') {
        setSelected("");
        setLastCorrectAnswer("");
        setFirstAnswer("");
        setSecondAnswer("");
        setQuestionText("");
        setCurrentQuestionNumber(0);

        history.push("/end/" + gameId);
      }

      if (data.status == "CrashedAfterStarted") {
        history.push("/");
      }
    });
  }
  function btnTextUI(answer, position) {
    return (
      <div className="text">{answer}
        {lastCorrectAnswer ?
          <div className="label">{position == 1 ? firstAnswerPercentage + " %" : secondAnswerPercentage + " %"}</div>
          : <Fragment />
        }
        {/* <div className="bar-chart" >
          <div className="inner" />
        </div> */}
      </div>
    )
  }
  return (
    <div className="layers" style={{ overflowY: "hidden" }}>
      <div className="game-page">
        <div className="top">
          {Array(20)
            .fill(0)
            .map((v, i) => {
              let clsName = "answered";
              if (i < currentQuestionNumber) {
                clsName = "remain";
              }
              if (i == currentQuestionNumber) {
                clsName = "current";
              }
              return (
                <div key={i} className={clsName}>
                  {i + 1}
                </div>
              );
            })}
        </div>
        <div className="left-bar">
          <div className="podium" />
          <div className="img" />
          <div className="content">
            <div className="number">{formatMoney(totalJackpot)}</div>
            <div className="label">Prize Fund</div>
          </div>
        </div>
        <div className="main-area">
          <div className="question">{questionText}</div>
          <div className="card-wrapper">
            <div className="podium">
              <div className="counterBar">
                <CircularProgressbar
                  counterClockwise={true}
                  value={questionTimer}
                  maxValue={5}
                  strokeWidth={25}
                  background={false}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    pathTransitionDuration: 0.5,
                    pathColor: "#00FFFF",
                    trailColor: "transparent",
                  })}
                />
              </div>
            </div>
            <div className="cwrap">
              <div className="shadow" />
              <div
                ref={firstButtonRef}
                onClick={() => {
                  if (firstAnswer && !isSpectating && !selected) {
                    selectAnswer(firstAnswer);
                  }
                }}
                // use correct-blank and wrong-blank classes for empty colored cards
                className={getBtnClassName("left", firstAnswer, firstButtonRef)}
              >
                {btnTextUI(firstAnswer, 1)}
              </div>
              <div
                ref={secondButtonRef}
                onClick={() => {
                  if (secondAnswer && !isSpectating && !selected) {
                    selectAnswer(secondAnswer);
                  }
                }}
                // use correct-blank and wrong-blank classes for empty colored cards
                className={getBtnClassName("right", secondAnswer, secondButtonRef)}
              >
                {btnTextUI(secondAnswer, 2)}
              </div>
            </div>
          </div>
        </div>
        <div className="right-bar">
          <div className="podium" />
          <div className="img" />
          <div className="content">
            <div className="number">{totalActivePlayers}/{totalPlayers}</div>
            <div className="label">Left in Play</div>
          </div>
        </div>
        <div className="footer">
          {isSpectating ? (
            <Link to="/" className="spectating">
              <div className="icon"></div>
              <div className="text">
                <div className="eye"></div>
                <span className="desktop">
                  You are now spectating, next game in {fancyTimeFormat(timer)}
                </span>
                <span className="mobile">Spectating</span>
              </div>
            </Link>
          ) : (
            jackpotUI()
          )}

          <Reactions gameId={gameId} />
        </div>
      </div>

      {/* popup modals */}
      {showSpectatingModal ? (
        <div className="modal spectating-modal">
          <div className="cont">
            <div className="icon"></div>
            <div className="btn"></div>
          </div>
        </div>
      ) : (
        <Fragment />
      )}

      {showEliminatedModal ? (
        <div className="modal eliminated-modal">
          <div className="cont">
            <div className="icon"></div>
            <div className="btn"></div>
          </div>
        </div>
      ) : (
        <Fragment />
      )}
    </div>
  );



  function jackpotUI() {
    if (!totalJackpot) {
      return <div className="earnings" />
    }
    return (
      <div className="earnings">
        <div className="icon"></div>
        <div className="text">
          <div className="amount">{totalActivePlayers>0?(formatMoney(totalJackpot / totalActivePlayers)):(formatMoney(totalJackpot))}</div>
          <div className="label">Potential Earnings</div>
        </div>
      </div>
    )
  }

  function getBtnClassName(position, answer, btnRef) {
    let cls = "card";
    cls += position == "left" ? " left" : " right";
    // console.log(answer, "a", lastCorrectAnswer);
   


    if (selected == answer && selected != "") {
      cls += " active";
    }

    if (lastCorrectAnswer && answer) {
      if (lastCorrectAnswer == answer) {
        cls += " correct";     
        //console.log(answer,"answer");
      } else if (lastCorrectAnswer) {
        cls += " wrong";
      }
    }
    return cls;
  }
}


