import "./App.scss";
import Game from "./pages/Game/Game";
import { Fragment, useContext, useEffect, useState } from "react";
import Entry from "./pages/Entry/Entry";
import { HashRouter, Route, Switch } from "react-router-dom";
import Countdown from "./pages/Countdown/Countdown";
import TempBingoWindow from "./pages/TempBingoWindow/TempBingoWindow";
import "react-circular-progressbar/dist/styles.css";
import GameEnd from "./pages/GameEnd/GameEnd";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {
  GameIdStore,
  IsAuth,
} from "./store/Store";
import InitApp from "./pages/InitApp";
import RotateDeviceMessage from "./components/RotateDeviceMessage";
import { SetAuth } from "./pages/SetAuth";

function App() {
  const [isAuth, setIsAuth] = useContext(IsAuth);
  const [appSyncGameId, setAppSyncGameId] = useContext(GameIdStore);
  const [theme, setTheme] = useState("");
  const query = new URLSearchParams(window.location.search);
 // console.log('query', query);


  useEffect(() => {
    setTheme('music');
    // const param = urlParams();
    // if (param.gameType == '23008') {
    // } else if (param.gameType == '23010') {
    //   setTheme('noughties');
    // } else if (param.gameType == '23007') {
    //   setTheme('eighties');
    // } else if (param.gameType == '23009') {
    //   setTheme('nineties');
    // }
    // else if (param.gameType == '23006') {
    //   setTheme('movies');
    // }
    //console.log('theme', theme);

  }, [])

  useEffect(() => {
   // console.log('isauth', isAuth);

  }, [isAuth])

  return (
    // eighties nineties noughties movies music
    <div className={"App " + theme}>
      <div>
        <ToastContainer />
        <HashRouter>
          <RotateDeviceMessage />
          <Route path="*" exact component={InitApp} />
          <Route path="/set/:token" exact component={SetAuth} />
          {isAuth ? (
            <Switch>
              <Route path={["/", "/ App*"]} exact component={Entry} />
              <Route path="/count/:gameId" exact component={Countdown} />
              <Route path="/game/:gameId" exact component={Game} />
              <Route path="/bingo" exact component={TempBingoWindow} />
              <Route path="/end/:gameId" exact component={GameEnd} />
            </Switch>
          ) : (
            <div className="layers">
              <Fragment />
            </div>
          )}
        </HashRouter>
      </div>
      <div className="footer-x">{appSyncGameId}</div>
    </div>
  );
}

export default App;
