import React, { useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { onUpdateFiftySync,  onCreateFiftySync } from "../../graphql/subscriptions";

export async function subscribeGame(appSyncGameId, callBack) {
  let unsub = (
    (await API.graphql(
      graphqlOperation(onUpdateFiftySync, {
        id: appSyncGameId,
      })
    )) as any
  ).subscribe((data) => {
    data = data?.value?.data.onUpdateFiftySync;
    // console.log(data);
    callBack({ data, unsub });
  });
}
